@font-face {
    font-family: 'AllianzNeo-Bold';
    src: url('AllianzNeo-Bold.eot');
    src: url('AllianzNeo-Bold.eot?#iefix') format('embedded-opentype'),
    url('AllianzNeo-Bold.woff2') format('woff2'),
    url('AllianzNeo-Bold.woff') format('woff'),
    url('AllianzNeo-Bold.ttf') format('truetype'),
    url('AllianzNeo-Bold.svg#AllianzNeo-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AllianzNeo-Condensed';
    src: url('AllianzNeo-Condensed.eot');
    src: url('AllianzNeo-Condensed.eot?#iefix')
    format('embedded-opentype'),
    url('AllianzNeo-Condensed.woff2') format('woff2'),
    url('AllianzNeo-Condensed.woff') format('woff'),
    url('AllianzNeo-Condensed.ttf') format('truetype'),
    url('AllianzNeo-Condensed.svg#AllianzNeo-Condensed')
    format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AllianzNeo-Light';
    src: url('AllianzNeo-Light.eot');
    src: url('AllianzNeo-Light.eot?#iefix') format('embedded-opentype'),
    url('AllianzNeo-Light.woff2') format('woff2'),
    url('AllianzNeo-Light.woff') format('woff'),
    url('AllianzNeo-Light.ttf') format('truetype'),
    url('AllianzNeo-Light.svg#AllianzNeo-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AllianzNeo-Italic';
    src: url('AllianzNeo-Italic.eot');
    src: url('AllianzNeo-Italic.eot?#iefix')
    format('embedded-opentype'),
    url('AllianzNeo-Italic.woff2') format('woff2'),
    url('AllianzNeo-Italic.woff') format('woff'),
    url('AllianzNeo-Italic.ttf') format('truetype'),
    url('AllianzNeo-Italic.svg#AllianzNeo-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AllianzNeo-SemiBold';
    src: url('AllianzNeo-SemiBold.eot');
    src: url('AllianzNeo-SemiBold.eot?#iefix')
    format('embedded-opentype'),
    url('AllianzNeo-SemiBold.woff2') format('woff2'),
    url('AllianzNeo-SemiBold.woff') format('woff'),
    url('AllianzNeo-SemiBold.ttf') format('truetype'),
    url('AllianzNeo-SemiBold.svg#AllianzNeo-SemiBold')
    format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AllianzNeo-SemiBoldItalic';
    src: url('AllianzNeo-SemiBoldItalic.eot');
    src: url('AllianzNeo-SemiBoldItalic.eot?#iefix')
    format('embedded-opentype'),
    url('AllianzNeo-SemiBoldItalic.woff2') format('woff2'),
    url('AllianzNeo-SemiBoldItalic.woff') format('woff'),
    url('AllianzNeo-SemiBoldItalic.ttf') format('truetype'),
    url('AllianzNeo-SemiBoldItalic.svg#AllianzNeo-SemiBoldItalic')
    format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AllianzNeo-Regular';
    src: url('AllianzNeo-Regular.eot');
    src: url('AllianzNeo-Regular.eot?#iefix')
    format('embedded-opentype'),
    url('AllianzNeo-Regular.woff2') format('woff2'),
    url('AllianzNeo-Regular.woff') format('woff'),
    url('AllianzNeo-Regular.ttf') format('truetype'),
    url('AllianzNeo-Regular.svg#AllianzNeo-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AllianzNeo-LightItalic';
    src: url('AllianzNeo-LightItalic.eot');
    src: url('AllianzNeo-LightItalic.eot?#iefix')
    format('embedded-opentype'),
    url('AllianzNeo-LightItalic.woff2') format('woff2'),
    url('AllianzNeo-LightItalic.woff') format('woff'),
    url('AllianzNeo-LightItalic.ttf') format('truetype'),
    url('AllianzNeo-LightItalic.svg#AllianzNeo-LightItalic')
    format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AllianzNeo-CondensedBold';
    src: url('AllianzNeo-CondensedBold.eot');
    src: url('AllianzNeo-CondensedBold.eot?#iefix')
    format('embedded-opentype'),
    url('AllianzNeo-CondensedBold.woff2') format('woff2'),
    url('AllianzNeo-CondensedBold.woff') format('woff'),
    url('AllianzNeo-CondensedBold.ttf') format('truetype'),
    url('AllianzNeo-CondensedBold.svg#AllianzNeo-CondensedBold')
    format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AllianzNeo-BoldItalic';
    src: url('AllianzNeo-BoldItalic.eot');
    src: url('AllianzNeo-BoldItalic.eot?#iefix')
    format('embedded-opentype'),
    url('AllianzNeo-BoldItalic.woff2') format('woff2'),
    url('AllianzNeo-BoldItalic.woff') format('woff'),
    url('AllianzNeo-BoldItalic.ttf') format('truetype'),
    url('AllianzNeo-BoldItalic.svg#AllianzNeo-BoldItalic')
    format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AllianzNeo-CondensedLight';
    src: url('AllianzNeo-CondensedLight.eot');
    src: url('AllianzNeo-CondensedLight.eot?#iefix')
    format('embedded-opentype'),
    url('AllianzNeo-CondensedLight.woff2') format('woff2'),
    url('AllianzNeo-CondensedLight.woff') format('woff'),
    url('AllianzNeo-CondensedLight.ttf') format('truetype'),
    url('AllianzNeo-CondensedLight.svg#AllianzNeo-CondensedLight')
    format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
