/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import 'assets/css/fonts/fonts';

/* You can add global styles to this file, and also import other style files */
:root {
    --white: #ffffff;
    --yellow: #fab600;
    --darkness-blue: #122b54;
    --dark-blue: #003781;
    --blue: #13a0d3;
    --light-blue: #dfeff2;
    --light-blue-two: #b5dae6;
    --pink: #f62459;
    --red: #f62459;
    --dark-pink: #a6276f;
    --green: #00908d;
    --gray: #3c3d3c;
    --light-gray: #fbfbfb;
    --font-regular: 'AllianzNeo-Regular';
    --font-bold: 'AllianzNeo-Bold';
    --font-semi-bold: 'AllianzNeo-SemiBold';
    --font-light-italic: 'AllianzNeo-LightItalic';
    --font-condensed-bold: 'AllianzNeo-CondensedBold';
    --font-bold-italic: 'AllianzNeo-BoldItalic';
    --font-condensed-light: 'AllianzNeo-CondensedLight';
}
body {
    font: {
        family: var(--font-regular), sans-serif;
        size: 14px;
    }
    color: var(--gray);

    *:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}
main {
    padding-top: 80px;

    @media screen and (min-width: 992px) {
        //padding-top: 142px;
        padding-top: 100px;
    }
}
img {
    max-width: 100%;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
button,
a {
    color: inherit;
    outline: none;
    font-size: inherit;
    border: 0;

    &:disabled {
        cursor: not-allowed;
    }
}
.wrapper-container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
}
.container {
    width: 100%;
    max-width: 1134px;
}
figure,
picture {
    margin: 0;
}
form {
    &.formCustom {
        .progress-wrap {
            ul {
                list-style: none;
                display: flex;
                justify-content: space-between;
                padding: 0;
                margin-bottom: 45px;
                z-index: 1;
                align-items: center;
                max-width: 92%;
                li {
                    width: 100%;
                }
            }
        }
        .last {
            width: auto !important;
        }
        .progressbar-dots {
            span {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border-radius: 100%;
                background: #f2f2f2;
                z-index: 2;
            }
            .line-progress-bar {
                position: relative;
                content: '';
                width: 100%;
                height: 1.5px;
                background-color: #bdbdbd;
                left: 0;
                top: 15px;

                &.active {
                    height: 4px;
                    background-color: var(--darkness-blue);
                }
                &.complete {
                    height: 4px;
                    background-color: var(--darkness-blue);
                }
            }

            &.active {
                color: var(--white);
                span {
                    background: var(--darkness-blue);
                }
            }
            &.complete {
                color: var(--white);
                span {
                    background: var(--darkness-blue);
                }
            }
        }
        .form-content {
            input,
            select {
                font-size: 16px;
                line-height: 1.1;
                color: var(--black);
                height: 37px;

                border: 1px solid var(--darkness-blue);
                box-sizing: border-box;
                border-radius: 6px;

                margin-top: 15px;
            }
            select {
                padding: 0.375rem 1rem 0.375rem 0.75rem;
                margin-bottom: 25px;
            }

            .contact {
                padding-bottom: 25px;

                label {
                    color: var(--darkness-blue);
                }
            }

            .field {
                .error-cep {
                    font-size: 11px;
                    color: var(--red);
                    text-transform: uppercase;
                    margin: 0 auto;
                    position: absolute;
                    left: 10px;
                    bottom: -18px;
                }
            }

            .title,
            p {
                color: #122b54;
                font-size: 24px;
                text-transform: none;
                text-align: center;

                &.warning {
                    font-size: 14px;
                    line-height: 19.6px;
                    margin-top: 1rem;
                    margin-bottom: 10px;
                }
            }

            .title {
                font-weight: 800;
            }

            .options {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
            }
        }
        .custom-tooltip {
            width: 18px;
            height: 18px;
            padding: 0;
            border: 2px solid #000;
            border-radius: 50%;
            display: inline-block;
            opacity: 1;
            line-height: 1.1;
            text-align: center;
            font-weight: 700;
            cursor: pointer;
        }
        .custom-link {
            color: #122b54;
            text-decoration: underline;
        }
        h3 {
            font: {
                weight: 400;
                size: 13px;
            }
            line-height: 1.2;
            color: var(--darkness-blue);
            margin-top: 16px;
            margin-bottom: 18px;
            text-align: center;
        }
        label {
            font-size: 12px;

            p {
                font-size: inherit !important;
                text-align: left !important;
                margin: 0;
            }
        }
        input {
            outline: none;
            box-shadow: none;
            color: var(--darkness-blue);

            &[type='radio'] {
                position: relative;
                display: inline-block;
                margin: {
                    top: 0;
                    right: 5px;
                }
                border: none;
            }
            &[type='radio']::before,
            &[type='radio']::after {
                position: absolute;
                content: '';
                display: inline-block;
            }
            &[type='radio']::before {
                height: 16px;
                width: 16px;
                border: 1px solid #999;
                left: 0px;
                top: 0px;
                background-color: #fff;
                border-radius: 2px;
            }
            &[type='radio']::after {
                height: 5px;
                width: 9px;
                left: 4px;
                top: 4px;
            }
            &[type='radio']:checked::after {
                content: '';
                border-left: 1px solid #fff;
                border-bottom: 1px solid #fff;
                transform: rotate(-45deg);
            }
            &[type='radio']:checked::before {
                background-color: var(--darkness-blue);
                border-color: var(--darkness-blue);
            }

            &[type='checkbox'] {
                position: relative;
                display: inline-block;
                margin: {
                    top: 0;
                    right: 5px;
                }
                border: none;
            }
            &[type='checkbox']::before,
            &[type='checkbox']::after {
                position: absolute;
                content: '';
                display: inline-block;
            }
            &[type='checkbox']::before {
                height: 16px;
                width: 16px;
                border: 1px solid #999;
                left: 0px;
                top: 0px;
                background-color: #fff;
                border-radius: 2px;
            }
            &[type='checkbox']::after {
                height: 5px;
                width: 9px;
                left: 4px;
                top: 4px;
            }
            &[type='checkbox']:checked::after {
                content: '';
                border-left: 1px solid #fff;
                border-bottom: 1px solid #fff;
                transform: rotate(-45deg);
            }
            &[type='checkbox']:checked::before {
                background-color: var(--darkness-blue);
                border-color: var(--darkness-blue);
            }

            &.form-control.disabled {
                pointer-events: none;
                cursor: not-allowed;
                background-color: #eceeef;
                opacity: 1;
            }
            &:disabled {
                cursor: not-allowed;
            }

            &::placeholder {
                color: var(--darkness-blue);
            }
        }
        .action-button {
            // margin-top: 25px;

            button {
                background: var(--darkness-blue);
                border: 1px solid var(--darkness-blue);
                border-radius: 6px;
                color: #fff;
                min-width: 80%;
                margin: 0 auto;
                padding: 8px;
                font-weight: 600;
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                text-transform: uppercase;

                &:focus {
                    outline: none;
                }

                &.back-step {
                    background: #fff;
                    color: var(--darkness-blue);
                }

                &:disabled {
                    opacity: 1;
                    cursor: not-allowed;
                    background-color: #e5e5e5;
                    border-color: #e5e5e5;
                }
            }
        }
        input[type='radio'].error::before,
        input[type='checkbox'].error::before {
            border-color: #d6041d;
        }
        .field {
            position: relative;
            margin-bottom: 25px;

            .control-error {
                margin: 0 auto;
                position: absolute;
                left: 10px;
                bottom: -18px;
            }

            .form-custom {
                color: #122b54;
            }
        }
        .field-no-bottom {
            margin-bottom: 0 !important;
        }
        .options {
            display: flex;
            align-items: center;
            justify-content: center;
            .option {
                padding: 0 10px;
            }
        }

        @media screen and (min-width: 480px) {
            h3 {
                text-align: left;
                font-size: 16px;
            }
        }

        @media screen and (min-width: 1200px) {
            h3 {
                font-size: 20px;
            }
            label {
                font-size: 14px;
            }
            .action-button {
                button {
                    padding: 10px;
                    font-size: 16px;
                }
            }
        }
    }
    .control-error {
        font-size: 11px;
        color: var(--red);
        text-transform: uppercase;
        display: block;
        margin: 5px auto 5px 5px;
    }
}
.red {
    color: var(--red) !important;
}
.title-section {
    color: var(--dark-blue);
    font-family: var(--font-bold);
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;

    @media screen and (min-width: 768px) {
        font-size: 40px;
    }
}
.description-section {
    color: var(--dark-blue);
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
        font-size: 24px;
    }
}
.modal {
    padding: 0 !important;
    background: rgba(31, 23, 35, 0.6);
    backdrop-filter: blur(8px);

    &-dialog {
        height: 100%;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 600px;
        padding: 15px;
    }
    &-content {
        border: none;
        border-radius: 0;
        width: 100%;
        max-height: 85vh;

        .close {
            position: absolute;
            right: 15px;
            top: -25px;
            background-color: var(--light-blue);
            opacity: 1 !important;
            color: var(--dark-blue);
            font-weight: 100;
            width: 50px;
            height: 50px;
            font-size: 32px;
            line-height: 1;
            border-radius: 0;
            z-index: 1;
        }
    }
    &-body {
        padding: 35px;
        max-height: 100%;
        overflow-y: auto;
    }
    &-figure {
        figure {
            max-width: 200px !important;
            margin-bottom: 20px;
        }
    }
    &-title {
        color: var(--dark-blue);
        font: {
            family: var(--font-bold);
            size: 22px;
        }
        line-height: 1.2;
        margin-bottom: 20px;
        @media screen and (min-width: 768px) {
            font-size: 32px;
        }
    }
    &-description {
        font-size: 18px;
        line-height: 1.2;
        margin-bottom: 20px;

        .small {
            font-size: 14px;
            font-style: italic;
        }
        @media screen and (min-width: 768px) {
            font-size: 22px;

            .small {
                font-size: 18px;
            }
        }
    }
    &-info {
        font-size: 12px;
        line-height: 1.2;
        margin-bottom: 0;

        a {
            color: inherit;
            text-decoration: none;
        }
    }
    &-button {
        width: 216px;
        height: 46px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        background: var(--dark-blue);
        color: #f2f2f2;
        text-transform: uppercase;
        border-radius: 40px;
        padding: 0;
        margin: 0 0 20px;
        outline: none !important;
        border: none !important;
    }
}
.assistence {
    padding: 0 15px;
}

.xp-show {
    display: none !important;
}

body:has(.xp-content) {
    .yellow-text-xp {
        color: #FBC105 !important;
    }
    .yellow-text-xp-after-before {
        &::after, &::before {
            border-color: #FBC105 !important;
        }
    }
    .yellow-bg-xp {
        background-color: #FBC105 !important;
    }
    .white-text-xp {
        color: #ffffff !important;
    }
    .white-bg-xp {
        background-color: #ffffff !important;
    }
    .mid-gray-text-xp {
        color: #ABAAA8 !important;
    }
    .mid-gray-text-xp-active.active {
        color: #ABAAA8 !important;

        &::after, &::before {
            border-bottom: 3px solid #ABAAA8 !important;
        }
    }
    .mid-gray-bg-xp {
        background-color: #ABAAA8 !important;
    }
    .light-gray-text-xp {
        color: #E8E8E8 !important;
    }
    .light-gray-bg-xp {
        background-color: #E8E8E8 !important;
    }
    .gray-text-xp {
        color: #808080 !important;
    }
    .gray-bg-xp {
        background-color: #808080 !important;
    }
    .dark-gray-text-xp {
        color: #414040 !important;
    }
    .dark-gray-bg-xp {
        background-color: #414040 !important;
    }
    .black-text-xp {
        color: #000000 !important;
    }
    .black-bg-xp {
        background-color: #000000 !important;
    }
    .black-bg-xp-active.active {
        button {
            background-color: #000000 !important;
            color: #ffffff !important;
        }
    }
    .xp-show {
        display: block !important;
    }

    .xp-hide {
        display: none !important;
    }
}

.xp-content {
    .yellow-text-xp {
        color: #FBC105 !important;
    }
    .yellow-text-xp-after-before {
        &::after, &::before {
            border-color: #FBC105 !important;
        }
    }
    .yellow-bg-xp {
        background-color: #FBC105 !important;
    }
    .white-text-xp {
        color: #ffffff !important;
    }
    .white-bg-xp {
        background-color: #ffffff !important;
    }
    .mid-gray-text-xp {
        color: #ABAAA8 !important;
    }
    .mid-gray-text-xp-active.active {
        color: #ABAAA8 !important;

        &::after, &::before {
            border-bottom: 3px solid #ABAAA8 !important;
        }
    }
    .mid-gray-bg-xp {
        background-color: #ABAAA8 !important;
    }
    .light-gray-text-xp {
        color: #E8E8E8 !important;
    }
    .light-gray-bg-xp {
        background-color: #E8E8E8 !important;
    }
    .gray-text-xp {
        color: #808080 !important;
    }
    .gray-bg-xp {
        background-color: #808080 !important;
    }
    .dark-gray-text-xp {
        color: #414040 !important;
    }
    .dark-gray-bg-xp {
        background-color: #414040 !important;
    }
    .black-text-xp {
        color: #000000 !important;
    }
    .black-bg-xp {
        background-color: #000000 !important;
    }
    .black-bg-xp-active.active {
        button {
            background-color: #000000 !important;
            color: #ffffff !important;
        }
    }
    .xp-show {
        display: block !important;
    }

    .xp-hide {
        display: none !important;
    }

    .wrapper-evidence:has(.xp-yellow-dots) {
        .wrapper-slider.container .xp-yellow-dots.slider-evidence {
            ul.slick-dots {
                li.slick-active, li.slick-active button, li.slick-active button::before {
                    background-color: #FBC105 !important;
                }

                li button {
                    border-color: #FBC105 !important;            
                }
            }
        }

    }

    .nav-tabs {
        max-width: 700px !important;
        
        @media(min-width: 1200px) {
            max-width: 900px !important;

        }

        li {
            margin: auto;
            button {
                height: fit-content !important;
                min-height: 30px;
            }
            // text-wrap-mode: nowrap;
        }
    }
}

.transform-rotate-180 {
    transform: rotateZ(180deg);
}

.red {
    color: var(--red);
}
